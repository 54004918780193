.ellipsis                   {overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.lineClamp {display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.page                       {
    &-info                  {padding: .4rem;background: #FFF;
        .hd                 {height: 1.2rem; display: flex;align-items: center;.fs(20);font-weight: 600;}
        .content            {.fs(14);color: #666;line-height: .68rem;}
    }

    &-search                {display: flex;padding: .4rem;overflow: hidden;background: #f9f9f9;z-index: 100;
        .search             {flex:1;margin-right: .4rem;position: relative;
            input           {display: block;width: 100%;height: 1rem;border: none;box-sizing: border-box;padding: 0 1rem 0 .4rem;.fs(14);color: #333;border-radius: .16rem;}
            a               {position: absolute;width: .5rem;height: .5rem;display: block;right: .2rem; top: 50%;margin-top: -.25rem;z-index: 100;
                i           {color: #999;.fs(20);}
            }
        }
        .submit             {width: 1.8rem;
            a               {display: block;height: 1rem;line-height: 1rem;.fs(14);color: #FFF;background: #fea130;border-radius: .16rem;text-align: center;}
        }
    }

    &-loop                  {padding: .4rem .4rem 0;background: #FFF;min-height: 100%;margin-bottom: .4rem;
        .hd                 {height: 1.2rem; display: flex;align-items: center;.fs(20);font-weight: 600;}
        .item               {padding: 1.6rem 0 .2rem;position: relative;border-bottom: .02rem solid #f3f3f3;
            &:last-child    {border: none;}
            .time           {position: absolute;top: .6rem;left: 0;.fs(14);color:#999;width: 100%;
                span        {float: right;color: #5ec2b5;.fs(14);}
            }
            .box            {display: flex;}
            .pic            {position: relative;width: 1.8rem;height: 1.8rem;overflow: hidden;border-radius: .1356rem;
                &:before    {content: '';display: block;padding-bottom: 100%;}
                img         {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
            }
            .info           {margin-left: .4rem;flex: 1;overflow: hidden;padding-right: 1.8rem;position: relative;
                .title      {.fs(16);font-weight: 600;color: #333;.ellipsis;}
                .title2     {.fs(14);color: #a0a4a3;.ellipsis;margin: .1rem 0;}
                .hits       {.fs(14);color: #333;}
                .price      {position: absolute;right: 0;top: 50%;margin-top: -.7rem;display: flex;flex-direction: column;.fs(12);align-items: center;
                    span    {color: #fea130;.fs(16);margin-top: .04rem;}
                }
            }
            .control        {display: flex;justify-content: space-between;height: 1.2rem;align-items: center;
                a           {border-radius: .4rem;display: inline-block;height: .8rem;line-height: .8rem;padding: 0 .5rem;border: .02rem solid #5ec2b5;color: #5ec2b5;}
            }
        }
        .none               {padding: 1rem 0;text-align: center;.fs(14);color: #999;}
    }
}